import React, { useState } from "react";
import { Table, Switch } from "antd";
import PropTypes from "prop-types";
import authService from "../api/authService";

function TableComponent({ data, fetchAllCampaigns }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const handleSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const handleSwitchChange = async (checked, key) => {

    try {
      await authService.updateCampaignStatus(
        key,
        checked ? "ACTIVE" : "PAUSED"
      );

      fetchAllCampaigns(key);
    } catch (error) {
      console.error("Error updating campaign status:", error);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
  };

  const columns = [
    {
      title: "Off/On",
      dataIndex: "offOn",
      key: "offOn",
      render: (text, record) => {
        return (
          <Switch
            checked={record.status == "ACTIVE" && true}
            onChange={(checked) => handleSwitchChange(checked, record.id)}
          />
        );
      },
    },
    {
      title: "CAMPAIGN NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "DATE START",
      dataIndex: "date_start",
      key: "date_start",
    },
    {
      title: "DATE STOP",
      dataIndex: "date_stop",
      key: "date_stop",
    },
    {
      title: "FREQUENCY",
      dataIndex: "frequency",
      key: "frequency",
    },
    {
      title: "IMPRESSIONS",
      dataIndex: "impressions",
      key: "impressions",
    },
    {
      title: "REACH",
      dataIndex: "reach",
      key: "reach",
    },
    {
      title: "CLICKS",
      dataIndex: "clicks",
      key: "clicks",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <Table
      rowSelection={rowSelection}
      columns={columns}
      dataSource={data}
      rowKey="key"
    />
  );
}

TableComponent.propTypes = {
  data: PropTypes.array.isRequired,
};

export default TableComponent;
