import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Select } from "antd";
import AutoSuggestSelect from "./AutoSuggestSelect";
import TreeSelectComponent from "./TreeSelectComponent";
import authService from "../api/authService";
import languagesData from "../utiles/languages.json"; // Adjust the path according to your project structure
import SelectTreePlacement from "./SelectTreePlacement";
import moment from 'moment'

const { Option } = Select;

const SecondStepForm = ({
  next,
  createdCampaign,
  setCreatedAdSet
}) => {
  const [valueTree, setValueTree] = useState();
  const [valueLocation, setValueLocation] = useState([]);
  const [placements, setPlacements] = useState(["facebook", "facebook:feed"]);
  const [error, setError] = useState("")

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: `${createdCampaign.name} Adset`,
      destination_type: "MESSENGER",
      // adType: "",
      page_id: "",
      optimization_goal: "CONVERSATIONS",
      budget: "daily_budget",
      amount: "",
      startDate: "",
      end_date: "",
      // startTime: "",
      end_time: "",
      minAge: 18,
      maxAge: 65,
      gender: "all",
      location: "",
      languages: [],
      placements: [],
      // audience: [],
    },
  });

  const onSubmit = async (data) => {
    try {
      let formData = {
        campaign: createdCampaign.id,
        name: data.name,
        destination_type: data.destination_type,
        promoted_object: {
          page_id: data.page_id
        },
        optimization_goal: data.optimization_goal,
        targeting: {
          age_min: data.minAge,
          age_max: data.maxAge,
        }
      }

      formData[data.budget] = data.amount

      if (data.gender === 'all') {
        formData.targeting.genders = [1,2]
      } else {
        formData.targeting.genders = [data.gender]
      }

      if (data.end_date) {
        console.log("here")
        formData.end_time = moment(`${data.end_date} ${data.end_time !== "" ? data.end_time : "00:00"}:00`, 'YYYY-MM-DD HH:mm:ss').unix()
      }

      valueLocation.forEach((location) => {
        if (!('geo_locations' in formData.targeting)) {
          formData.targeting.geo_locations = {}
        }
        const [locationType, locationKey] = location.split(":")
        if (locationType === "city") {
          if (!('cities' in formData.targeting.geo_locations)) {
            formData.targeting.geo_locations.cities = [{
              key: locationKey
            }]
          } else {
            formData.targeting.geo_locations.cities.push({
              key: locationKey
            })
          }
        } else {
          if (!('countries' in formData.targeting.geo_locations)) {
            formData.targeting.geo_locations.countries = [locationKey]
          } else {
            formData.targeting.geo_locations.countries.push(locationKey)
          }
        }
      })

      placements.forEach((placement) => {
        if (['facebook', 'instagram', 'messenger', 'audience_network'].includes(placement)) {
          if (!('publisher_platforms' in formData.targeting)) {
            formData.targeting.publisher_platforms = [placement]
          } else {
            formData.targeting.publisher_platforms.push(placement)
          }
        } else {
          const [publisherType, publisherPlacement] = placement.split(":")
          if (!(`${publisherType}_positions` in formData.targeting)) {
           formData.targeting[`${publisherType}_positions`] = [publisherPlacement]
          } else {
            formData.targeting[`${publisherType}_positions`].push(publisherPlacement)
          }
        }
      })

      // if (data.end_date && data.end_time) {
      //
      // }


      // data.audience = valueTree;
      // data.location = valueLocation;
      // data.placements = valuePlacementTree;
      // data.minAge = Number(data.minAge);
      // data.maxAge = Number(data.maxAge);

      // const response = await authService.secondStepForm(data);
      console.log("Form submitted successfully:");
      const createdAdSet = await authService.createAdSet(formData)

      setCreatedAdSet(createdAdSet)

      next();
    } catch (error) {
      setError(error.response.data.detail)
      console.error("Error submitting form:", error);
    }
  };

  return (
    <form className="form__wrapper" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Controller
          name="name"
          control={control}
          rules={{ required: "Ad set name is required" }}
          render={({ field }) => (
            <>
              <p>Ad Set Name</p>
              <input
                id="name"
                placeholder="New Engagement ad set"
                {...field}
              />
              {errors.name && (
                <div className="error-message">{errors.name.message}</div>
              )}
            </>
          )}
        />

        <Controller
          name="destination_type"
          control={control}
          rules={{ required: "Destination Type is required" }}
          render={({ field }) => (
            <>
              <p>Campaign Objective</p>
              <Select
                {...field}
                style={{ width: "100%" }}
                placeholder="Choose a destination type"
              >
                <Option value="MESSENGER">Messaging Apps</Option>
                <Option value="on_your_ad" disabled>
                  On Your Ad
                </Option>
                <Option value="calls" disabled>
                  Calls
                </Option>
                <Option value="website" disabled>
                  Website
                </Option>
                <Option value="app" disabled>
                  App
                </Option>
                <Option value="facebook_page" disabled>
                  Facebook Page
                </Option>
              </Select>
              {errors.objective && (
                <div className="error-message">{errors.destination_type.message}</div>
              )}
            </>
          )}
        />

        {/*<Controller*/}
        {/*  name="adType"*/}
        {/*  control={control}*/}
        {/*  rules={{ required: "Ad type is required" }}*/}
        {/*  render={({ field }) => (*/}
        {/*    <>*/}
        {/*      <p>Ad Type</p>*/}
        {/*      <Select*/}
        {/*        {...field}*/}
        {/*        style={{ width: "100%" }}*/}
        {/*        placeholder="Choose an ad type"*/}
        {/*      >*/}
        {/*        <Option value="click_message">Click to Message</Option>*/}
        {/*        <Option value="sponsored_message">Sponsored Message</Option>*/}
        {/*      </Select>*/}
        {/*      {errors.adType && (*/}
        {/*        <div className="error-message">{errors.adType.message}</div>*/}
        {/*      )}*/}
        {/*    </>*/}
        {/*  )}*/}
        {/*/>*/}

        <Controller
          name="page_id"
          control={control}
          rules={{ required: "Page ID is required" }}
          render={({ field }) => (
            <>
              <p>Page ID</p>
              <input id="pageId" placeholder="Enter Page ID" {...field} />
              {errors.pageId && (
                <div className="error-message">{errors.pageId.message}</div>
              )}
            </>
          )}
        />

        <Controller
          name="optimization_goal"
          control={control}
          rules={{ required: "Optimization goal is required" }}
          render={({ field }) => (
            <>
              <p>Performance Goal</p>
              <Select
                {...field}
                style={{ width: "100%" }}
                placeholder="Choose a optimization goal"
              >
                <Option value="CONVERSATIONS">
                  Maximize Number of Conversations
                </Option>
                <Option value="clicks_number" disabled>
                  Maximize Number of Link Clicks
                </Option>
              </Select>
              {errors.performanceGoal && (
                <div className="error-message">
                  {errors.optimization_goal.message}
                </div>
              )}
            </>
          )}
        />

        <div className="budget__wrapper">
          <Controller
            name="budget"
            control={control}
            rules={{ required: "Budget type is required" }}
            render={({ field }) => (
              <div>
                <p>Budget Type</p>
                <Select
                  {...field}
                  style={{ width: "100%" }}
                  placeholder="Choose budget type"
                >
                  <Option value="daily_budget">Daily Budget</Option>
                  <Option value="lifetime_budget">Lifetime Budget</Option>
                </Select>
                {errors.budget && (
                  <div className="error-message">{errors.budget.message}</div>
                )}
              </div>
            )}
          />

          <Controller
            name="amount"
            control={control}
            rules={{ required: "Budget is required" }}
            render={({ field }) => (
              <>
                <input type="number" id="amount" placeholder="$20.00" {...field} />
                {errors.amount && (
                  <div className="error-message">{errors.amount.message}</div>
                )}
              </>
            )}
          />
        </div>

        <div className="date-time__wrapper">
        {/*  <div className="time__wrapper">*/}
        {/*    <Controller*/}
        {/*      name="startDate"*/}
        {/*      control={control}*/}
        {/*      rules={{ required: "Start date is required" }}*/}
        {/*      render={({ field }) => (*/}
        {/*        <div className="date">*/}
        {/*          <p>Start Date</p>*/}
        {/*          <input*/}
        {/*            type="date"*/}
        {/*            id="startDate"*/}
        {/*            placeholder="Select start date"*/}
        {/*            {...field}*/}
        {/*          />*/}
        {/*          {errors.startDate && (*/}
        {/*            <div className="error-message">*/}
        {/*              {errors.startDate.message}*/}
        {/*            </div>*/}
        {/*          )}*/}
        {/*        </div>*/}
        {/*      )}*/}
        {/*    />*/}

        {/*    <div className="start__date">*/}
        {/*      <Controller*/}
        {/*        name="startTime"*/}
        {/*        control={control}*/}
        {/*        rules={{ required: "Start time is required" }}*/}
        {/*        render={({ field }) => (*/}
        {/*          <>*/}
        {/*            <input*/}
        {/*              type="time"*/}
        {/*              id="startTime"*/}
        {/*              placeholder="Select start time"*/}
        {/*              {...field}*/}
        {/*            />*/}
        {/*            {errors.startTime && (*/}
        {/*              <div className="error-message">*/}
        {/*                {errors.startTime.message}*/}
        {/*              </div>*/}
        {/*            )}*/}
        {/*          </>*/}
        {/*        )}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}

          <div className="time__wrapper">
            <Controller
              name="end_date"
              control={control}
              render={({ field }) => (
                <div className="date">
                  <p>End Date</p>
                  <input
                    type="date"
                    id="end_date"
                    placeholder="Select end date"
                    {...field}
                  />
                  {errors.end_date && (
                    <div className="error-message">
                      {errors.end_date.message}
                    </div>
                  )}
                </div>
              )}
            />

            <div className="end__date">
              <Controller
                name="end_time"
                control={control}
                render={({ field }) => (
                  <>
                    <input
                      type="time"
                      id="end_time"
                      placeholder="Select end time"
                      {...field}
                    />
                    {errors.end_time && (
                      <div className="error-message">
                        {errors.end_time.message}
                      </div>
                    )}
                  </>
                )}
              />
            </div>
          </div>
        </div>

        <div className="age__wrapper">
          <Controller
            name="minAge"
            control={control}
            rules={{ required: "Minimum age is required", min: 18, max: 65 }}
            render={({ field }) => (
              <div className="age">
                <p>Minimum Age</p>
                <input
                  id="minAge"
                  type="number"
                  placeholder="Min age"
                  {...field}
                />
                {errors.minAge && (
                  <div className="error-message">{errors.minAge.message}</div>
                )}
              </div>
            )}
          />
          <Controller
            name="maxAge"
            control={control}
            render={({ field }) => (
              <div className="age">
                <p>Maximum Age</p>
                <input
                  id="maxAge"
                  type="number"
                  placeholder="Max age"
                  {...field}
                />
                {errors.maxAge && (
                  <div className="error-message">{errors.maxAge.message}</div>
                )}
              </div>
            )}
          />
        </div>

        <Controller
          name="gender"
          control={control}
          rules={{ required: "Gender is required" }}
          render={({ field }) => (
            <div>
              <p>Gender</p>
              <Select {...field} style={{ width: "100%" }} placeholder="Gender">
                <Option value="all">All</Option>
                <Option value={1}>Male</Option>
                <Option value={2}>Female</Option>
              </Select>

              {errors.gender && (
                <div className="error-message">{errors.gender.message}</div>
              )}
            </div>
          )}
        />

        <div>
          <p>Location</p>
          <AutoSuggestSelect
            value={valueLocation}
            setValue={setValueLocation}
          />
        </div>

        {/*<Controller*/}
        {/*  name="languages"*/}
        {/*  control={control}*/}
        {/*  render={({ field }) => (*/}
        {/*    <div>*/}
        {/*      <p>Languages</p>*/}
        {/*      <Select*/}
        {/*        {...field}*/}
        {/*        mode="multiple"*/}
        {/*        style={{ width: "100%" }}*/}
        {/*        placeholder="Select languages"*/}
        {/*        showSearch*/}
        {/*        filterOption={(input, option) =>*/}
        {/*          option.children.toLowerCase().includes(input.toLowerCase())*/}
        {/*        }*/}
        {/*      >*/}
        {/*        {languagesData.map((lang) => (*/}
        {/*          <Option key={lang.key} value={lang.key}>*/}
        {/*            {lang.name}*/}
        {/*          </Option>*/}
        {/*        ))}*/}
        {/*      </Select>*/}
        {/*      {errors.languages && (*/}
        {/*        <div className="error-message">{errors.languages.message}</div>*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*/>*/}

        {/* <div>
          <p>Audience</p>
          <TreeSelectComponent value={valueTree} setValue={setValueTree} />
          {errors.audience && (
            <div className="error-message">{errors.audience.message}</div>
          )}
        </div> */}
        <div>
          <p>Placements</p>
          <SelectTreePlacement
            value={placements}
            setValue={setPlacements}
          />
        </div>
      </div>
      <div className="btn__second-step">
        {error && <p>{error}</p>}
        <Button type="primary" htmlType="submit">
          Continue
        </Button>
      </div>
    </form>
  );
};

export default SecondStepForm;
