import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Select } from "antd";
import authService from "../api/authService";

const { Option } = Select;

const ThirdStepForm = ({ valuePlacementTree, selectedAccountName, createdAdSet }) => {
  console.log("valuePlacementTree", valuePlacementTree);
  console.log(createdAdSet)

  const [error, setError] = useState("")
  const [iframeUrl, setIframeUrl] = useState(null); // State to store iframe URL
  const [pagePost, setPagePost] = useState("");
  const [createdAd, setCreatedAd] = useState(null);

  const adFormatMappings = {
    facebook_feed: "DESKTOP_FEED_STANDARD",
    right_hand_column: "RIGHT_COLUMN_STANDARD",
    marketplace: "MARKETPLACE_MOBILE",
    video_feeds: "INSTREAM_VIDEO_FULLSCREEN_MOBILE"
  }

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: `${createdAdSet.name} Ad`,
      pagePost: "",
      selectedOption: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      const ad = await authService.createAd({
        adset: createdAdSet.id,
        name: data.name,
        creative: {"object_story_id": createdAdSet.promoted_object.page_id + "_" + getValues("pagePost")},
      })
      setCreatedAd(ad)
      await authService.publishAd(ad.id)
    } catch (error) {
      setError(error.response.data.detail)
      console.error("Error submitting form:", error);
    }
  };

  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }

  const handlePreviewClick = async () => {
    try {
      const fetchedIframeUrl = await authService.fetchIframeUrl(
        selectedAccountName.ad_account_id,
        {"object_story_id": createdAdSet.promoted_object.page_id + "_" + getValues("pagePost")},
        adFormatMappings[getValues("selectedOption")]
      ); // Fetch iframe URL from authService
      setIframeUrl(fetchedIframeUrl); // Update state with fetched URL
    } catch (error) {
      console.error("Error fetching iframe URL:", error);
    }
  };

  return (
    <div>
      <form className="form__wrapper" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          control={control}
          rules={{ required: "Ad Name is required" }}
          render={({ field }) => (
            <>
              <p>Ad Set Name</p>
              <input
                id="name"
                placeholder="New Engagement Ad"
                {...field}
              />
              {errors.name && (
                <div className="error-message">{errors.name.message}</div>
              )}
            </>
          )}
        />

        <Controller
          name="pagePost"
          control={control}
          rules={{ required: "Page Post is required" }}
          render={({ field }) => (
            <>
              <p>Page Post</p>
              <input
                id="pagePost"
                placeholder="Post ID"
                {...field}
              />
              {errors.pagePost && (
                <div className="error-message">{errors.pagePost.message}</div>
              )}
            </>
          )}
        />

        <Controller
          name="selectedOption"
          control={control}
          render={({ field }) => (
            <>
              <p>Select Option</p>
              <Select
                {...field}
                style={{ width: "100%" }}
                placeholder="Select an option"
                options={[
                  {
                    label: <span>Facebook</span>,
                    title: 'Facebook',
                    options: createdAdSet.targeting.facebook_positions ? createdAdSet.targeting.facebook_positions.map((placement) => {
                      return {
                        label: <span>{toTitleCase(placement.replace("_", " "))}</span>,
                        value: "facebook_" + placement,
                        disabled: placement !== "feed"
                      }
                    }) : []
                  },
                  {
                    label: <span>Instagram</span>,
                    title: 'Instagram',
                    options: createdAdSet.targeting.instagram_positions ? createdAdSet.targeting.instagram_positions.map((placement) => {
                      return {
                        label: <span>{toTitleCase(placement.replace("_", " "))}</span>,
                        value: placement,
                        disabled: true
                      }
                    }) : []
                  },
                  {
                    label: <span>Messenger</span>,
                    title: 'Messenger',
                    options: createdAdSet.targeting.messenger_positions ? createdAdSet.targeting.messenger_positions.map((placement) => {
                      return {
                        label: <span>{toTitleCase(placement.replace("_", " "))}</span>,
                        value: placement,
                        disabled: true
                      }
                    }) : []
                  },
                  {
                    label: <span>Audience Network</span>,
                    title: 'Audience Network',
                    options: createdAdSet.targeting.audience_network_positions ? createdAdSet.targeting.audience_network_positions.map((placement) => {
                      return {
                        label: <span>{toTitleCase(placement.replace("_", " "))}</span>,
                        value: placement,
                        disabled: true
                      }
                    }) : []
                  }
                ]}
              >
              </Select>
            </>
          )}
        />

        <br />
        <div className="preview__iframe">
          <div>
            <Button type="primary" onClick={handlePreviewClick}>
              Preview iframe
            </Button>
          </div>
          <br />
          <div>
            {iframeUrl && (
              <div dangerouslySetInnerHTML={{ __html: iframeUrl }} />
              // <iframe
              //   src={iframeUrl}
              //   width="400"
              //   height="400"
              //   style={{ border: "none" }}
              //   title="Embedded Content"
              // />
            )}
          </div>
        </div>

        <div>
          {error && <p>{error}</p>}
          <Button className="continue__btn" type="primary" htmlType="submit">
            Publish
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ThirdStepForm;
