import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate, useLocation
} from 'react-router-dom';
import authService from "./api/authService";
import "./App.css";
import DashboardPage from "./pages/DashboardPage";
import LoginPage from "./pages/LoginPage";



function RequireAuth({ children }) {
  const isAuthenticated = !!authService.getToken();

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" />;
  }

  return children;
}


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        {/*<Route path="/dashboard" element={<DashboardPage />} />*/}
        <Route
          path="/dashboard"
          element={<RequireAuth> <DashboardPage /> </RequireAuth>} />}
        />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Router>
  );
}

export default App;
