import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Menu,
  Select,
  Steps,
  Drawer,
  Space,
  message,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import authService from "../api/authService";
import TableComponent from "../components/TableComponent";
import SecondStepForm from "../components/SecondStepForm";
import ThirdStepForm from "../components/ThirdStepForm";

const { Option } = Select;

function DashboardPage() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [adAccounts, setAdAccounts] = useState([]);
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [selectedAccountName, setSelectedAccountName] = useState([]);
  const [open, setOpen] = useState(false);
  const [valuePlacementTree, setValuePlacementTree] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [createdCampaign, setCreatedCampaign] = useState([]);
  const [createdAdSet, setCreatedAdSet] = useState();

  const [step2Data, setStep2Data] = useState([]);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      campaign_name: null,
      objective: "OUTCOME_ENGAGEMENT",
      buying_type: "AUCTION",
    },
  });

  const onSubmit = async (data) => {
    console.log("Selected value:", data);
    try {
      const campaign = await authService.createCampaign({
        ad_account: selectedAccountName.id,
        ...data
      });
      // const data = await authService.fetchStep2Data();
      // setStep2Data(data);
      setCreatedCampaign(campaign)
      next();
    } catch (error) {
      console.error("Error fetching Step 2 data:", error);
      message.error("Failed to fetch data for Step 2.");
    }
  };

  const validateAndProceed = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(onSubmit)();
    } else {
      message.error("Something went wrong.");
    }
  };
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent((prev) => prev + 1);
  };

  const prev = () => {
    setCurrent((prev) => prev - 1);
  };

  const steps = [
    {
      title: "New campaign",
      content: (
        <>
          <form className="form__wrapper">
            <Controller
              name="name"
              control={control}
              rules={{ required: "Campaign name is required" }}
              render={({ field }) => (
                <>
                  <p>Campaign name</p>
                  <input
                    {...field} // Spread the field object here to connect the input with react-hook-form
                    placeholder="Enter campaign name" // Optionally add a placeholder
                  />
                  {errors.campaign_name && (
                    <div className="error-message">
                      {errors.campaign_name.message}
                    </div>
                  )}
                </>
              )}
            />
            <Controller
              name="objective"
              control={control}
              rules={{ required: "Campaign objective is required" }}
              render={({ field }) => (
                <>
                  <p>Campaign objective</p>
                  <Select
                    {...field}
                    style={{ width: "100%" }}
                    placeholder="Choose a campaign objective"
                  >
                    <Option value="OUTCOME_ENGAGEMENT">Engagement</Option>
                    <Option value="Traffic" disabled>
                      Traffic
                    </Option>
                    <Option value="Awareness" disabled>
                      Awareness
                    </Option>
                    <Option value="Leads" disabled>
                      Leads
                    </Option>
                    <Option value="App promotion" disabled>
                      App promotion
                    </Option>
                    <Option value="Sales" disabled>
                      Sales
                    </Option>
                  </Select>
                  {errors.objective && (
                    <div className="error-message">
                      {errors.objective.message}
                    </div>
                  )}
                </>
              )}
            />
            <Controller
              name="buying_type"
              control={control}
              rules={{ required: "Buying Type is required" }}
              render={({ field }) => (
                <>
                  <p>Buying type</p>
                  <Select
                    {...field}
                    style={{ width: "100%" }}
                    placeholder="Choose a Buying Type"
                  >
                    <Option value="AUCTION">Auction</Option>
                    <Option value="Reach and frequency" disabled>
                      Reach and frequency
                    </Option>
                  </Select>
                  {errors.buying_type && (
                    <div className="error-message">
                      {errors.buying_type.message}
                    </div>
                  )}
                </>
              )}
            />
            {/* <TreeSelectComponent />
            <AutoSuggestSelect /> */}
            <Button
              type="primary"
              className="continue__btn"
              onClick={validateAndProceed}
            >
              Continue
            </Button>
          </form>
        </>
      ),
    },
    {
      title: "New Adset",
      content: (
        <SecondStepForm
          next={next}
          setCreatedAdSet={setCreatedAdSet}
          createdCampaign={createdCampaign}
        />
      ),
    },
    {
      title: "New Ads",
      content: <ThirdStepForm valuePlacementTree={selectedOptions} selectedAccountName={selectedAccountName} createdAdSet={createdAdSet} />,
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const fetchAllCampaigns = async (key) => {
    const campaignsResponse = await authService.fetchCampaigns(key);
    setCampaigns(campaignsResponse);
  };

  const fetchAdAccounts = async () => {
    try {
      const fetchedAdAccounts = await authService.fetchAdAccounts();

      setAdAccounts(fetchedAdAccounts);

      if (fetchedAdAccounts.length > 0) {
        setSelectedAccountName(fetchedAdAccounts[0]);
        setSelectedItem(fetchedAdAccounts[0].ad_account_id);
        const campaignsResponse = await authService.fetchCampaigns(
          fetchedAdAccounts[0].id
        );
        setCampaigns(campaignsResponse);
      }
    } catch (error) {
      console.error("Error fetching ad accounts:", error);
    }
  };

  useEffect(() => {
    fetchAdAccounts();
  }, []);

  const style = {
    backgroundImage: 'url("./icon.png")',
    backgroundPosition: "0px -330px",
    backgroundSize: "auto",
    width: "24px",
    height: "24px",
    backgroundRepeat: "no-repeat",
    display: "inline-block",
  };

  const secondStyle = {
    backgroundImage: 'url("./icon.png")',
    backgroundPosition: "0px -333px",
    backgroundSize: "auto",
    width: "24px",
    height: "24px",
    backgroundRepeat: "no-repeat",
    display: "inline-block",
  };

  const dropdownItems = adAccounts.map((account) => ({
    label: (
      <div className="dropdown__options">
        <i
          alt=""
          data-visualcompletion="css-img"
          className="img"
          style={style}
        ></i>
        <div>
          <h2>{account.name}</h2>
          <span>Ad account ID: {account.ad_account_id}</span>
        </div>
      </div>
    ),
    key: account.id,
  }));

  const handleMenuClick = async (e) => {
    const { key } = e;

    setSelectedItem(key);
    const selectedAccount = adAccounts.find(
      (account) => account.ad_account_id === key
    );
    if (selectedAccount) {
      setSelectedAccountName(selectedAccount);
    }

    try {
      const campaignsResponse = await authService.fetchCampaigns(key);
      setCampaigns(campaignsResponse);
    } catch (error) {
      console.error("Error fetching data based on selection:", error);
    }
  };

  const handleLogout = () => {
    authService.logout();
    navigate("/login");
  };

  return (
    <div>
      <div className="header__wrapper">
        <div className="dropdown">
          <h1>Ad accounts</h1>
          <Dropdown
            overlay={<Menu items={dropdownItems} onClick={handleMenuClick} />}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <i
                alt=""
                data-visualcompletion="css-img"
                className="img"
                style={secondStyle}
              ></i>{" "}
              {selectedAccountName.name} ({selectedAccountName.ad_account_id})
            </a>
          </Dropdown>
        </div>
        <div>
          <div onClick={handleLogout}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path
                d="M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8"
                stroke="#374151"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="header__table">
        <button onClick={showDrawer}>+ Create</button>
      </div>
      <TableComponent data={campaigns} fetchAllCampaigns={fetchAllCampaigns} />
      <Drawer
        title="Create a new account"
        width={1000}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <Steps current={current} items={items} />
        <div>{steps[current].content}</div>
        {/* <div style={{ marginTop: 24 }}>
          {current === steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => message.success("Processing complete!")}
            >
              Done
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              Previous
            </Button>
          )}
        </div> */}
      </Drawer>
    </div>
  );
}

export default DashboardPage;
